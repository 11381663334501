<form [formGroup]="familyInformationStep" class="form">
  <field-holder kind="3-cols">
    <mat-form-field appearance="outline">
      <mat-label>{{
        'NEW_FOSTER_PARENT.FORM.HOUSEHOLD_STATUS' | translate
      }}</mat-label>
      <mat-select formControlName="householdStatus" required>
        <mat-option *ngFor="let householdStatus of residentalStatusOptions" [value]="householdStatus">
          {{ householdStatus.name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="
          familyInformationStep.get('householdStatus').hasError('required')
        ">
        {{ 'NEW_FOSTER_PARENT.FORM.INPUT_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
  </field-holder>
  <field-holder >
    <field-holder>
      <h5 class="area-title">
        {{
          'NEW_FOSTER_PARENT.FAMILY_INFORMATION.AVERAGE_MONTHLY_INCOME'
            | translate
        }}
      </h5>
      <field-holder kind="3-cols">
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_FOSTER_PARENT.FORM.EMPLOYMENT' | translate
          }}</mat-label>
          <input matInput type="text" formControlName="employmentIncome" autocomplete="off" maxlength="255" />
          <span matSuffix>{{ 'NEW_FOSTER_PARENT.FORM.RSD' | translate }}</span>
          <mat-error *ngIf="
              familyInformationStep.get('employmentIncome').hasError('pattern')
            ">
            {{ 'NEW_FOSTER_PARENT.FORM.MONEY_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_FOSTER_PARENT.FORM.PRIVATE_ACTIVITY' | translate
          }}</mat-label>
          <input matInput type="text" formControlName="privateActivityIncome" autocomplete="off" maxlength="255" />
          <span matSuffix>{{ 'NEW_FOSTER_PARENT.FORM.RSD' | translate }}</span>
          <mat-error *ngIf="
              familyInformationStep
                .get('privateActivityIncome')
                .hasError('pattern')
            ">
            {{ 'NEW_FOSTER_PARENT.FORM.MONEY_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
      </field-holder>
      <field-holder kind="3-cols">
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_FOSTER_PARENT.FORM.PENSION' | translate
          }}</mat-label>
          <input matInput type="text" formControlName="pensionIncome" autocomplete="off" maxlength="255" />
          <span matSuffix>{{ 'NEW_FOSTER_PARENT.FORM.RSD' | translate }}</span>
          <mat-error *ngIf="
              familyInformationStep.get('pensionIncome').hasError('pattern')
            ">
            {{ 'NEW_FOSTER_PARENT.FORM.MONEY_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_FOSTER_PARENT.FORM.OTHER' | translate
          }}</mat-label>
          <input matInput type="text" formControlName="otherIncome" autocomplete="off" maxlength="255" />
          <span matSuffix>{{ 'NEW_FOSTER_PARENT.FORM.RSD' | translate }}</span>
          <mat-error *ngIf="
              familyInformationStep.get('otherIncome').hasError('pattern')
            ">
            {{ 'NEW_FOSTER_PARENT.FORM.MONEY_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_FOSTER_PARENT.FORM.MONTHLY_INCOME' | translate
          }}</mat-label>
          <input matInput type="text" formControlName="monthlyIncome" autocomplete="off" maxlength="255" />
          <span matSuffix>{{ 'NEW_FOSTER_PARENT.FORM.RSD' | translate }}</span>
          <mat-error *ngIf="
              familyInformationStep.get('monthlyIncome').hasError('pattern')
            ">
            {{ 'NEW_FOSTER_PARENT.FORM.MONEY_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
      </field-holder>
      
    </field-holder>
    
  </field-holder>
    <form-subgroup-divider></form-subgroup-divider>

  <field-holder>
      <h5 class="area-title">
        {{
          'NEW_FOSTER_PARENT.FAMILY_INFORMATION.AVERAGE_MONTHLY_INCOME_PER_HOUSEHOLD_MEMBER'
            | translate
        }}
      </h5>
      <field-holder kind="2-cols">
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_FOSTER_PARENT.FORM.MONTHLY_INCOME_PER_HOUSEHOLD_MEMBER' | translate
          }}</mat-label>
          <input matInput type="text" formControlName="perHouseholdMemberIncome" autocomplete="off" maxlength="255" />
          <span matSuffix>{{ 'NEW_FOSTER_PARENT.FORM.RSD' | translate }}</span>
          <mat-error *ngIf="
              familyInformationStep.get('perHouseholdMemberIncome').hasError('pattern')
            ">
            {{ 'NEW_FOSTER_PARENT.FORM.MONEY_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
      </field-holder>
    </field-holder>
  <form-subgroup-divider></form-subgroup-divider>
  <field-holder kind="3-cols">
    <mat-form-field appearance="outline">
      <mat-label>{{
        'NEW_FOSTER_PARENT.FORM.FAMILY_MEMBER_COUNT' | translate
      }}</mat-label>
      <input matInput type="text" formControlName="familyMemberCount" required autocomplete="off" maxlength="255" />
      <mat-error *ngIf="
          familyInformationStep.get('familyMemberCount').hasError('required')
        ">
        {{ 'NEW_FOSTER_PARENT.FORM.INPUT_ERROR' | translate }}
      </mat-error>
      <mat-error *ngIf="
                    familyInformationStep
                      .get('familyMemberCount')
                      .hasError('pattern')
                  ">
        {{ 'NEW_FOSTER_PARENT.FORM.MONEY_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{
        'NEW_FOSTER_PARENT.FORM.CHILDREN_COUNT' | translate
      }}</mat-label>
      <input matInput type="text" formControlName="childrenCount" required autocomplete="off" maxlength="255" />
      <mat-error *ngIf="familyInformationStep.get('childrenCount').hasError('required')">
        {{ 'NEW_FOSTER_PARENT.FORM.INPUT_ERROR' | translate }}
      </mat-error>
      <mat-error *ngIf="
              familyInformationStep
                .get('childrenCount')
                .hasError('pattern')
            ">
        {{ 'NEW_FOSTER_PARENT.FORM.MONEY_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{
        'NEW_FOSTER_PARENT.FORM.FOSTER_CHILDREN_TO_ACCEPT_COUNT' | translate
      }}</mat-label>
      <input matInput type="text" formControlName="acceptFosterChildrenCount" required autocomplete="off" maxlength="255" />
      <mat-error *ngIf="
          familyInformationStep
            .get('acceptFosterChildrenCount')
            .hasError('required')
        ">
        {{ 'NEW_FOSTER_PARENT.FORM.INPUT_ERROR' | translate }}
      </mat-error>
      <mat-error *ngIf="
              familyInformationStep
                .get('acceptFosterChildrenCount')
                .hasError('pattern')
            ">
        {{ 'NEW_FOSTER_PARENT.FORM.MONEY_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
  </field-holder>
  <field-holder kind="3-cols">
    <mat-form-field appearance="outline">
      <mat-label>{{
        'NEW_FOSTER_PARENT.FORM.FOSTER_CHILDREN_GENDER' | translate
      }}</mat-label>
      <mat-select formControlName="fosterChildrenGender" multiple>
        <mat-option *ngFor="let option of genderOptions" [value]="option">
          {{ option.gender }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="
          familyInformationStep.get('fosterChildrenGender').hasError('required')
        ">
        {{ 'NEW_FOSTER_PARENT.FORM.INPUT_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{
        'NEW_FOSTER_PARENT.FORM.FOSTER_CHILDREN_AGE_TO_ACCEPT' | translate
      }}</mat-label>
      <mat-select formControlName="ageToAccept" multiple>
        <mat-option *ngFor="let option of ageChildOptions" [value]="option">
          {{ option.title }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="familyInformationStep.get('ageToAccept').hasError('required')">
        {{ 'NEW_FOSTER_PARENT.FORM.INPUT_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{
        'NEW_FOSTER_PARENT.FORM.FOSTER_CHILDREN_NATIONALITY_TO_ACCEPT'
          | translate
      }}</mat-label>
      <mat-select formControlName="acceptDifferentNationality">
        <mat-option *ngFor="let option of yesNoOptions" [value]="option">
          {{ option.viewValue | translate }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="
          familyInformationStep
            .get('acceptDifferentNationality')
            .hasError('required')
        ">
        {{ 'NEW_FOSTER_PARENT.FORM.INPUT_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
  </field-holder>
  <field-holder kind="3-cols">
    <mat-form-field appearance="outline">
      <mat-label>{{
        'NEW_FOSTER_PARENT.FORM.FOSTER_CHILDREN_WITH_SPECIAL_NEEDS' | translate
      }}</mat-label>
      <mat-select formControlName="acceptChildrenWithSpecialNeeds">
        <mat-option *ngFor="let option of yesNoOptions" [value]="option">
          {{ option.viewValue | translate }}
        </mat-option>
      </mat-select>
      <mat-hint>{{
        'NEW_FOSTER_PARENT.FORM.FOSTER_CHILDREN_WITH_SPECIAL_NEEDS' | translate
      }}</mat-hint>
      <mat-error *ngIf="
          familyInformationStep
            .get('acceptChildrenWithSpecialNeeds')
            .hasError('required')
        ">
        {{ 'NEW_FOSTER_PARENT.FORM.INPUT_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{
        'NEW_FOSTER_PARENT.FORM.FOSTER_CHILDREN_WITH_BEHAVIOUR_DISORDER'
          | translate
      }}</mat-label>
      <mat-select formControlName="acceptChildrenWithBehaviourDisorder">
        <mat-option *ngFor="let option of yesNoOptions" [value]="option">
          {{ option.viewValue | translate }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="
          familyInformationStep
            .get('acceptChildrenWithBehaviourDisorder')
            .hasError('required')
        ">
        {{ 'NEW_FOSTER_PARENT.FORM.INPUT_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
  </field-holder>
  <form-footer>
    <button matStepperPrevious mat-stroked-button color="primary"
      class="form-footer__button form-footer__button--secondary">
      {{ 'NEW_FOSTER_PARENT.PREVIOUS_STEP' | translate }}
    </button>
    <button mat-stroked-button matStepperNext color="primary" [disabled]="!familyInformationStep.valid "
      class="form-footer__button" (click)="updateFamilyInformationStep()">
      {{ 'NEW_FOSTER_PARENT.NEXT_STEP' | translate }}
    </button>
  </form-footer>
</form>
