/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, Inject, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import {
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Page } from 'src/app/page';
import { WarningMessageComponent } from 'src/app/warning-message/warning-message.component';
import { AdoptedChildService } from 'src/services/adopted-child.service';
import { CustomPaginationService } from 'src/services/custom-pagination.service';
import { SharedCaseService } from 'src/services/shared-case.service';
import { ToastrImplService } from 'src/services/toastr.service';
import { ModalEventEnum, ModalSizeEnum } from 'src/types';
import { PreviewDocumentComponent } from '../../sidebar-widget/preview-document/preview-document.component';
import { AnnulmentOfAdoptionComponent } from './annulment-of-adoption/annulment-of-adoption.component';

@Component({
   selector: 'adopted-children',
   templateUrl: './adopted-children.component.html',
   styleUrls: ['./adopted-children.component.scss'],
   encapsulation: ViewEncapsulation.None,
})
export class AdoptedChildrenComponent implements OnInit {
   selectable = true;
   removable = true;
   readonly separatorKeysCodes: number[] = [COMMA, ENTER];
   public searchTerms: any[] = [];
   page: Page<any> = new Page();
   public globalFilter = '';
   public searchItems: any[] = [];
   year: any;
   nowYear: any = new Date().getFullYear();
   collection: any = new Object();
   myInput = '';
   object: { origin: string };

   displayedColumns: string[] = ['rBr', 'id', 'registrationId', 'firstName', 'lastName', 'dateOfBirth', 'jmbg', 'actions'];

   constructor(
      private adoptedChildService: AdoptedChildService,
      private sharedCaseService: SharedCaseService,
      public dialog: MatDialog,
      @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
      private paginationService: CustomPaginationService,
      public router: Router,
      private translate: TranslateService,
      private toastr: ToastrImplService
   ) {
      this.clearSharedCaseService();
      this.object = { ...data };
      this.year = new Date().getFullYear();
      this.doFilter();
   }

   ngOnInit(): void {}

   public getNextPage(): void {
      this.page.pageable = this.paginationService.getNextPage(this.page);
      this.doFilter();
   }

   public getPreviousPage(): void {
      this.page.pageable = this.paginationService.getPreviousPage(this.page);
      this.doFilter();
   }

   public getPageInNewSize(pageSize: any): void {
      console.log(this.page);
      this.page.pageable = this.paginationService.getPageInNewSize(this.page, pageSize);
      console.log(this.page);
      this.doFilter();
   }

   handleOpenDialog(element: any) {
      this.dialog.open(PreviewDocumentComponent, {
         disableClose: true,
         panelClass: 'overlay-panel',
         data: { document: { name: `${element.firstName} ${element.lastName}` } },
      });
   }

   handleCreateReport(element: any) {
      console.log(element.id);
   }

   onRowClicked(element: any) {
      this.router.navigateByUrl('/adopted-children/' + element.id);
   }

   add(event: MatChipInputEvent): void {
      const input = event.input;
      const value = event.value;

      // Add new search term
      if ((value || '').trim()) {
         this.searchItems.push(value.trim());
      }
      // Reset the input value
      if (input) {
         input.value = '';
      }
      this.searchTerms = this.searchItems.map(searchItem => searchItem.name);
      this.globalFilter = '';
      this.applyFilter(this.searchTerms);
   }

   remove(item: any): void {
      const index = this.searchItems.indexOf(item);
      if (index >= 0) {
         this.searchItems.splice(index, 1);
         this.searchTerms = this.searchItems.map(function (searchItem) {
            return searchItem.name;
         });
         this.applyFilter(this.searchTerms);
      }
   }

   applyFilter(filterValue: any) {
      this.globalFilter = filterValue;
      this.doFilter();
   }

   clearSharedCaseService() {
      this.sharedCaseService.tabs = [];
      this.sharedCaseService.activeTab = null;
   }

   doFilter() {
      if (this.year === undefined) {
         this.year = this.nowYear;
      }
      const body = {
         text: this.searchItems,
         year: this.year,
      };
      this.adoptedChildService.getFilteredPage(this.page.pageable, body).subscribe(page => {
         this.page = page;
         this.page.pageable.pagePerShow = page.number + 1;
         for (const element of page.content) {
            element.beforeAdoptionDateOfBirth = element.beforeAdoptionDateOfBirth?.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$1.$2.$3.');
         }
      });
   }

   somethingChanged() {
      if (this.year === undefined) {
         this.year = this.nowYear;
      }
      this.doFilter();
   }

   closeCollection() {
      const object = {
         message: null,
         title: null,
      };

      this.translate.get('WARNING_DIALOG.CLOSE_COLLECTION.MESSAGE').subscribe((res: string) => {
         object.message = res;
      });
      this.translate.get('WARNING_DIALOG.CLOSE_COLLECTION.TITLE').subscribe((res: string) => {
         object.title = res;
      });
      const dialogRef = this.dialog.open(WarningMessageComponent, {
         disableClose: true,
         width: ModalSizeEnum.MINI,
         data: object,
         panelClass: 'overlay-panel',
      });

      dialogRef.afterClosed().subscribe(res => {
         if (res.data !== undefined) {
            if (res.data.status) {
               this.adoptedChildService.closeCollection(this.year).subscribe(result => {
                  this.collection = result;
               });
            }
         }
      });
   }

   annulmentOfAdoption(element: any) {
      let dialogRef: any;
      dialogRef = this.dialog.open(AnnulmentOfAdoptionComponent, {
         width: ModalSizeEnum.DEFAULT,
         panelClass: 'overlay-panel',
      });
      dialogRef.afterClosed().subscribe((result: any) => {
         if (result) {
            if (result.data && result.event === ModalEventEnum.SUCCESS) {
               this.adoptedChildService.updateAnnulmentOfAdoption(element.id, result.data).subscribe(result1 => {
                  this.toastr.success('SNACKBAR.INPUT_ADDED');
                  element.annulmentOfAdoption = result.data;
               });
            }
         }
      });
   }

   public exportCsv(): void {
      const body = {
         text: this.searchItems,
         year: this.year,
      };
      this.adoptedChildService.exportCSV(body);
   }

   printPDF() {
      const body = {
         text: this.searchItems,
         year: this.year,
      };
      this.adoptedChildService.getPdf(body);
   }
}
